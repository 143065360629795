import { endpoints } from '@/constants';
import { eCustomFieldTypes, eModeType } from '@/enums';
import router from '@/router';
import { store } from '@/store';
import { getIdToken } from 'firebase/auth';
import { arrayUnion, collection, deleteDoc, doc, DocumentData, DocumentReference, DocumentSnapshot, getDoc, getDocs, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { fbAuth, currFirestore, deepCopy, nonDebugLog, standardApiFetch, fbStorage } from './initialization-utils';
import { t } from './language-utils';
import { hideLoading, showLoading } from './loading-utils';
import { generateId, showError } from './misc-firestore-utils';
import { getParameterByName, isAtMaxModes, removeUndefinedValues, routerDupeErrorCatch } from './misc-utils';
import { showSnackBar } from './notice-utils';
import { deleteFileFromStorage } from './storage-utils';
import { batchUpdateUserDoc, batchUpdateUserModeGatewayDoc, deleteCustomPermalink, getUserDoc, getUserModeGatewayDoc, updateUserLocal, updateUserModeGatewayLocal } from './user-utils';
import { deleteImages } from './image-utils';

// Used to update a mode without changing the active mode. Cannot be used to create new modes.
export const updateMode = (modeDocId: string, update: Readonly<Partial<Mode>>, partOfBulkOperation = false): Promise<DocumentReference<DocumentData>> => {
  return new Promise((resolve, reject) => {
    if (!partOfBulkOperation) {
      showLoading();
    }
    const modeDoc = doc(currFirestore, getUserModeGatewayDoc().path, 'modes', modeDocId);

    const fullModeUpdate = {
      ...removeUndefinedValues(update),
      dateUpdated: Timestamp.fromMillis(Date.now()),
    };

    const newModes: ModesMap = {
      ...store.state.modes,
      [modeDocId]: {
        ...store.state.modes[modeDocId],
        ...fullModeUpdate,
      },
    };

    store.commit('modes', newModes);

    nonDebugLog('Updating mode: ', fullModeUpdate);

    updateDoc(modeDoc, fullModeUpdate)
      .then(() => {
        if (!partOfBulkOperation) {
          showSnackBar(t.modeSaved);
        }
        resolve(modeDoc);
      })
      .catch((error: any) => {
        showError(`Could not save the mode:`, error, true);
        reject(null);
      })
      .finally(() => {
        if (!partOfBulkOperation) {
          hideLoading();
        }
      });
  });
};

// Takes a complete mode object and saves/updates it based on the data in the oject. Used when editing a mode on the Edit Mode Form or when creating a mode.
export const saveMode = (modeData: Partial<AnyMode>): Promise<DocumentReference<DocumentData>> => {
  return new Promise((resolve, reject) => {
    showLoading();

    const isForUpdate = Boolean(modeData.isForUpdate);
    const dateThisModeWasLastUpdatedInSeconds = modeData.dateUpdated?.seconds || 0;
    delete modeData.isForUpdate;

    if (!isForUpdate && isAtMaxModes()) {
      hideLoading();
      return reject(null);
    }

    modeData.docId = modeData.docId || generateId();

    const modes = store.state.modes;
    const currGateway = store.state.currUserModeGateway;

    interface ModeToUpdate extends Mode {
      // These are fields that we will want to format if they exist.
      phone?: string | null;
      phoneWork?: string | null;
    }

    let modeDataToPush = {
      ...modeData,
      index: modeData.index || Object.keys(modes).length + 1,
      dateUpdated: Timestamp.fromMillis(Date.now()),
    } as ModeToUpdate;

    const nameWasChanged = modeDataToPush.name !== store.state.modes[modeDataToPush.docId]?.name || modeDataToPush.displayName !== store.state.modes[modeDataToPush.docId]?.displayName;

    if (isForUpdate) {
      if (modeDataToPush.dateCreated?.seconds && modeDataToPush.dateCreated?.nanoseconds) {
        modeDataToPush.dateCreated = new Timestamp(modeDataToPush.dateCreated?.seconds, modeDataToPush.dateCreated?.nanoseconds); // Fix copying problem
      } else {
        modeDataToPush.dateCreated = Timestamp.fromMillis(Date.now());
      }
    }

    // Format the phone numbers if they exist. Fallback values must be undefined so that they get removed below.
    modeDataToPush.phone = modeDataToPush.phone ? (modeDataToPush.phone as string).toString().replace(/[^0-9.+]/g, '') : undefined;
    modeDataToPush.phoneWork = modeDataToPush.phoneWork ? (modeDataToPush.phoneWork as string).toString().replace(/[^0-9.+]/g, '') : undefined;

    // Remove pointless falsy values before committing.
    modeDataToPush = removeUndefinedValues(modeDataToPush);
    const publicUserModeGatewayDoc = getUserModeGatewayDoc();
    const modeDoc = doc(currFirestore, publicUserModeGatewayDoc.path, 'modes', modeDataToPush.docId);
    const onBatchFailArray: { (): void }[] = [];
    const batch = writeBatch(currFirestore);

    if (isForUpdate) {
      // Check if changes were made on the server after the user started editing this mode. We don't want them to overrite changes made by someone else using the account. Or changed made on another tab.
      getDoc(modeDoc).then((modeToUpdateDocSnap: DocumentSnapshot<DocumentData>) => {
        const currServerModeData = modeToUpdateDocSnap.data() as Mode;
        const modeWasRecentlyUpdatedOnServer = currServerModeData.dateUpdated && currServerModeData.dateUpdated?.seconds > dateThisModeWasLastUpdatedInSeconds;
        if (modeWasRecentlyUpdatedOnServer) {
          showError(t.refreshPageToGetLatestMode);
          hideLoading();
          reject(null); // Stop here since the user needs to refresh to get the updated data.
        } else {
          proceedAfterServerComparison();
        }
      });
    } else {
      proceedAfterServerComparison();
    }

    function proceedAfterServerComparison() {
      if (!isForUpdate) {
        // Add to modeOrder if we aren't just updating an existing mode that's already in that array
        const folderId = getParameterByName('folderId');

        const updateNonFolderModeOrder = () => {
          if (!store.getters.modeOrder.includes(modeDataToPush.docId)) {
            // Simple update so both firebase and local store update.
            const firebaseUpdate = { modeOrder: arrayUnion(modeDataToPush.docId) };
            const localStoreUpdate = { modeOrder: [...store.getters.modeOrder, modeDataToPush.docId] as string[] };
            batchUpdateUserDoc(batch, firebaseUpdate, localStoreUpdate, onBatchFailArray);
          } else {
            showError(`Something went wrong. A Sitch ordering should not contain duplicates.`, null, true);
          }
        };

        modeDataToPush.dateUpdated = Timestamp.fromMillis(Date.now());
        modeDataToPush.dateCreated = Timestamp.fromMillis(Date.now());
        // This conditional is just for extra safety. a Mode ordering should never had dupes.
        if (folderId) {
          const matchingFolder = store.state.folders[folderId];

          if (matchingFolder) {
            const firebaseUpdate = { [`folders.${matchingFolder.id}.modeOrder`]: [...matchingFolder.modeOrder, modeDataToPush.docId] };
            const localStoreUpdate = {
              folders: {
                ...store.state.currUser.folders,
                [matchingFolder.id]: {
                  ...matchingFolder,
                  modeOrder: [...matchingFolder.modeOrder, modeDataToPush.docId],
                },
              },
            };
            batchUpdateUserDoc(batch, firebaseUpdate, localStoreUpdate, onBatchFailArray);
          } else {
            updateNonFolderModeOrder();
          }
        } else {
          updateNonFolderModeOrder();
        }
      }

      if (!modeDataToPush.linkId) {
        // Create a shortned link for this mode if it does not yet have one.
        const currUser = fbAuth.currentUser;

        if (!currUser) {
          showError(`No logged in user`);
          hideLoading();
          return;
        }

        currUser
          .getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            standardApiFetch(endpoints.createShortPermalink, {
              idToken,
              userId: store.state.userId,
              modeId: modeDataToPush.docId,
            }).then((response) => {
              modeDataToPush.linkId = response.successfulResponse.linkId;
              commitToFirestore();
            });
          })
          .catch((error) => {
            showError(`Could not get user token to make request`, error, true);
          });
      } else {
        commitToFirestore();
      }

      function commitToFirestore() {
        nonDebugLog('Saving mode: ', modeDataToPush);

        const newModes: ModesMap = {
          ...modes,
          [modeDataToPush.docId]: { ...modeDataToPush, dateUpdated: Timestamp.fromMillis(Date.now()) } as any,
        };

        const userModeGatewayExists = Boolean(currGateway.docId); // If the docId exists then the publicUserModeGateway document exists in the database and we can update.
        if (userModeGatewayExists) {
          // Create or update the mode.
          batch.set(modeDoc, modeDataToPush);

          // If we are also changing the active mode then save that change as well.
          if (!currGateway.activeModeId) {
            batchUpdateUserModeGatewayDoc(batch, { activeModeId: modeDataToPush.docId, activeModeLinkId: modeDataToPush.linkId, activeModeOwnerId: '' }, onBatchFailArray);
          }

          batch
            .commit()
            .then(() => {
              onSuccessfulUpdate();

              // Update mode name in teams if it was changed.
              if (nameWasChanged) {
                getDocs(collection(currFirestore, getUserDoc().path, 'teams'))
                  .then((querySnap) => {
                    const newCreatedTeams: { [teamId: string]: Team } = {};
                    if (querySnap.size) {
                      querySnap.forEach((docSnap) => {
                        const team = docSnap.data() as Team;
                        if (team.modeIds.includes(modeDataToPush.docId)) {
                          const newModesForTeam = team.modes.map((mode) => {
                            if (mode.docId === modeDataToPush.docId) {
                              mode.name = modeDataToPush.name;
                              mode.displayName = modeDataToPush.displayName;
                            }
                            return mode;
                          });
                          const updatePartial: Partial<Team> = {
                            modes: newModesForTeam,
                          };
                          updateDoc(docSnap.ref, updatePartial);
                        }
                        newCreatedTeams[team.docId] = team;
                      });
                      store.commit('createdTeams', newCreatedTeams);
                    }
                  })
                  .catch((error) => {
                    showError(`Could not update relevant teams with the mode update:`, error, true);
                  });

                if (store.state.currUser.externalUseModeGroups) {
                  // Make a deep copy of vuex property since we might edit it and it doesn't have complex objects nested in it.
                  const newExternalUseModeGroups: { [entryCode: string]: ExternalUseModeGroupOnUser } = deepCopy(store.state.currUser.externalUseModeGroups);

                  const batch = writeBatch(currFirestore);
                  Object.values(newExternalUseModeGroups).forEach((group) => {
                    const modeToUpdate = group.modes.find((externalUseMode) => {
                      return externalUseMode.docId === modeDataToPush.docId;
                    });
                    if (modeToUpdate) {
                      // Only bother doing an update if the mode we saved is in this external use mode group.
                      const updatedModes = group.modes.map((externalUseMode) => {
                        if (modeToUpdate.docId === externalUseMode.docId) {
                          // Make a copy of the object since we're changing an object that's nested in vuex state.
                          return {
                            ...externalUseMode,
                            name: modeDataToPush.name,
                            displayName: modeDataToPush.displayName || '',
                          };
                        }
                        return externalUseMode;
                      });

                      batch.update(doc(currFirestore, 'externalUseModeGroups', group.docId), {
                        modes: updatedModes,
                      });

                      newExternalUseModeGroups[group.docId].modes = updatedModes;
                    }
                  });

                  batch.update(getUserDoc(), {
                    externalUseModeGroups: newExternalUseModeGroups,
                  });

                  batch.commit().then(() => {
                    updateUserLocal({
                      externalUseModeGroups: newExternalUseModeGroups,
                      dateUpdated: Timestamp.fromMillis(Date.now()),
                    });
                  });
                }
              }
            })
            .catch((error) => {
              onBatchFailure(error);
            })
            .finally(() => {
              hideLoading();
            });
        } else {
          // Else we need to create the docment
          // First create the gateway. The merge should technically never make a difference since we should always be creating from scratch in this case, but just to be safe. Why not?
          const newUserModeGateway: Partial<PublicUserModeGateway> = {
            ...currGateway,
            docId: store.state.userId,
            dateCreated: Timestamp.fromMillis(Date.now()),
            dateUpdated: Timestamp.fromMillis(Date.now()),
            activeModeId: modeDataToPush.docId,
            activeModeLinkId: modeDataToPush.linkId,
          };
          // Security rules do not permit us to send this data. So delete it.
          delete (newUserModeGateway as any).isSitchLinkActivated;
          delete (newUserModeGateway as any).premiumSubscriptionId;

          batch.set(publicUserModeGatewayDoc, newUserModeGateway, { merge: true });
          batch.set(modeDoc, modeDataToPush);
          batch
            .commit()
            .then(() => {
              updateUserModeGatewayLocal(newUserModeGateway);
              onSuccessfulUpdate();
            })
            .catch((error) => {
              onBatchFailure(error);
            })
            .finally(() => {
              hideLoading();
            });
        }
        function onBatchFailure(error: any) {
          reject(null);
          onBatchFailArray.forEach((func: () => void) => func());
          showError(`Could not save the mode:`, error, true);
        }
        function onSuccessfulUpdate() {
          resolve(modeDoc);
          showSnackBar(t.modeSaved);
          store.commit('modes', newModes);
          if (document.location.pathname !== '/') {
            router.push('/').catch(routerDupeErrorCatch);
          }
        }
      }
    }
  });
};

export const deleteMode = ({
  mode,
  showMessages,
  shouldUpdateUserDoc,
  currSelectedFolder,
}: {
  mode: AnyMode;
  showMessages: boolean;
  shouldUpdateUserDoc: boolean;
  currSelectedFolder: Folder | null;
}): Promise<void> => {
  return new Promise((resolve, reject) => {
    const modeOrder = store.getters.modeOrder;
    const newUserModeObj: PublicUserModeGateway = { ...store.state.currUserModeGateway };
    const newModesMap: ModesMap = { ...store.state.modes };
    const isNotInFolder = modeOrder.includes(mode.docId);
    let userDocUpdateChanges: Partial<PlatformUser>;
    let newActiveModeIdCandidate = '';
    delete newModesMap[mode.docId];
    const newModeOrder: string[] = (store.getters.modeOrder as string[]).filter((modeId) => modeId !== mode.docId);
    newActiveModeIdCandidate = newModeOrder[0];

    if (shouldUpdateUserDoc) {
      if (isNotInFolder) {
        userDocUpdateChanges = {
          modeOrder: newModeOrder,
        };
      } else {
        const currUser: PlatformUser = store.state.currUser;
        const currFolder: Folder = currSelectedFolder as Folder;
        const newModeOrderForCurrFolder = currFolder.modeOrder.filter((modeId: string) => modeId !== mode.docId);
        const folders = {
          ...currUser.folders,
          [currFolder.id]: {
            ...currFolder,
            modeOrder: newModeOrderForCurrFolder,
          },
        };
        userDocUpdateChanges = {
          folders,
        };
        newActiveModeIdCandidate = newActiveModeIdCandidate || newModeOrderForCurrFolder[0];
      }
    }

    const onBatchFailArray: { (): void }[] = [];
    let newActiveModeId = 'notChanged';

    const onModeDeleteReady = () => {
      const batch = writeBatch(currFirestore);

      let gatewayUpdate: Partial<PublicUserModeGateway> = {};

      // Update activeModeId if we're deleting the active mode.
      if (newUserModeObj.activeModeId === mode.docId) {
        newActiveModeId = newActiveModeIdCandidate || '';
        const newActiveMode = store.state.modes[newActiveModeId];
        gatewayUpdate = {
          activeModeId: newActiveModeId,
          activeModeLinkId: newActiveMode?.linkId || '',
          dateUpdated: Timestamp.fromMillis(Date.now()),
        };
        batchUpdateUserModeGatewayDoc(batch, gatewayUpdate, onBatchFailArray);
      }

      if (shouldUpdateUserDoc) {
        // Fix indexing.
        const firebaseUpdate: Record<string, unknown> = {
          ...userDocUpdateChanges,
          dateUpdated: Timestamp.fromMillis(Date.now()),
        };
        const localStoreUpdate: Partial<PlatformUser> = {
          ...userDocUpdateChanges,
          dateUpdated: Timestamp.fromMillis(Date.now()),
        };

        batchUpdateUserDoc(batch, firebaseUpdate, localStoreUpdate, onBatchFailArray);
      }

      if (mode.linkId) {
        batch.delete(doc(currFirestore, 'shortPermalinks', mode.linkId));
      }

      // Do the delete.
      const modeDoc = doc(currFirestore, getUserModeGatewayDoc().path, 'modes', mode.docId);

      batch.delete(modeDoc);

      const permalinkId = store.state.currUser.permalinks?.[mode.docId];
      if (permalinkId) {
        deleteCustomPermalink(mode.docId, permalinkId, batch);
      }

      const commitBatch = () => {
        batch
          .commit()
          .then(() => {
            resolve();
            store.commit('modes', newModesMap);
            if (newActiveModeId === '') {
              if (showMessages) {
                showSnackBar(t?.modeDeletedAndDeviceInactive);
              }
            } else {
              if (showMessages) {
                showSnackBar(t?.modeDeleted);
              }
            }

            const currUser = fbAuth.currentUser;

            if (!currUser) {
              if (showMessages) {
                showError(`No logged in user`, null, true);
              }

              return;
            }

            // Special mode type deletion considerations
            switch (mode.type) {
              case eModeType.chat:
                {
                  // Delete custom permalink if it applies, and associated chat rooms if this is a chat mode.
                  getIdToken(currUser, /* forceRefresh */ true)
                    .then((idToken) => {
                      // Delete permalink if this mode has one.

                      standardApiFetch(endpoints.deleteChat, {
                        idToken,
                        userId: store.state.userId,
                        modeId: mode.docId,
                      }).then(() => {
                        nonDebugLog('Associated chat room was also deleted.');
                      });
                    })
                    .catch((error) => {
                      showError(`Could not get user token to make request.`, error, true);
                    });
                }
                break;
              case eModeType.trivia:
              case eModeType.wordle:
              case eModeType.chess: {
                deleteDoc(doc(currFirestore, 'activeGames', mode.docId));
                break;
              }
            }

            // Delete mode from any teams that use it.
            getDocs(collection(currFirestore, getUserDoc().path, 'teams'))
              .then((querySnap) => {
                const newCreatedTeams: { [teamId: string]: Team } = {};
                if (querySnap.size) {
                  querySnap.forEach((docSnap) => {
                    const team = docSnap.data() as Team;
                    if (team.modeIds.includes(mode.docId)) {
                      const newModeIds = team.modeIds.filter((id) => id !== mode.docId);
                      const newModes = team.modes.filter((teamMode) => teamMode.docId !== mode.docId);
                      const updatePartial: Partial<Team> = {
                        modeIds: newModeIds,
                        modes: newModes,
                      };
                      updateDoc(docSnap.ref, updatePartial);
                    }
                    newCreatedTeams[team.docId] = team;
                  });
                  store.commit('createdTeams', newCreatedTeams);
                }
              })
              .catch((error) => {
                if (showMessages) {
                  showError(`Could not update relevant teams with mode deletion:`, error, true);
                }
              });
          })
          .catch((error) => {
            reject();
            if (showMessages) {
              showError(`Could not delete the Sitch.`, error, true);
            }
            onBatchFailArray.forEach((func: () => void) => func());
          });
      };

      const collectionsToDelete: string[] = [];

      switch (mode.type) {
        case eModeType.blog:
          collectionsToDelete.push('postContent');
          break;
        case eModeType.shop:
          collectionsToDelete.push('promoCodes');
          break;
        case eModeType.booking:
        case eModeType.customForm:
          collectionsToDelete.push('submissions');
          break;
      }

      if (collectionsToDelete.length) {
        collectionsToDelete.forEach((collectionName) => {
          getDocs(collection(currFirestore, modeDoc.path, collectionName)).then((querySnapshot) => {
            querySnapshot.forEach((modeDocSnap) => {
              // If this is for a custom form check if the custom form has any uploaded files associated with it and delete them.
              if (mode.type === eModeType.customForm) {
                const submissionData = modeDocSnap.data() as CustomFormSubmission;
                Object.values(submissionData.customFields).forEach((field) => {
                  if (field.type === eCustomFieldTypes.fileUpload) {
                    const storageFileArray = field.value as StorageFile[];
                    storageFileArray.forEach((file) => {
                      deleteObject(ref(fbStorage, `${file.storagePath}/${file.id}`));
                    });
                  }
                });
              }
              batch.delete(modeDocSnap.ref);
            });
            commitBatch();
          });
        });
      } else {
        commitBatch();
      }
    };

    // Delete all images on all items in the passed in array if they contain an "images" or "secondaryImages" prop.
    function deleteAllImages(arrayOfItemsWithImages: any[]): Promise<any[]> {
      const promiseArray: Promise<any>[] = [];
      arrayOfItemsWithImages.forEach((item: any) => {
        promiseArray.push(deleteImages(item?.images));
        promiseArray.push(deleteImages(item?.secondaryImages));
      });

      return Promise.all(promiseArray);
    }

    let typedMode;
    const promiseArray: Promise<void>[] = [];
    switch (mode.type) {
      case eModeType.files:
        typedMode = mode as Mode & FilesMode;
        if (typedMode.files) {
          typedMode.files.forEach((storageFile: StorageFile) => {
            promiseArray.push(deleteFileFromStorage(storageFile.storagePath, storageFile.fileName));
          });
          Promise.all(promiseArray)
            .then(() => {
              onModeDeleteReady();
            })
            .catch(() => {
              showError(`Could not delete Sitch because a file associated with this Stich could not be deleted.`, null, true);
            });
        } else {
          onModeDeleteReady();
        }
        break;
      default:
        {
          const promises = [];
          promises.push(deleteAllImages([mode]));
          for (const prop in mode) {
            const currVal = (mode as any)[prop];
            if (Array.isArray(currVal)) {
              promises.push(deleteAllImages(currVal));
            }
          }
          Promise.all(promiseArray).then(() => {
            onModeDeleteReady();
          });
        }
        break;
    }
  });
};
