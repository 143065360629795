import { getEmptyDetailedAddress, PREMIUM_MAX_SITCHES, PURCHASER_MAX_SITCHES, STANDARD_MAX_SITCHES, ZERO_DECIMAL_CURRENCIES } from '@/constants';
import { eFeatureFlagTypes, eCustomFieldTypes, eModeType } from '@/enums';
import { store } from '@/store';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import storage from 'local-storage-fallback';
import { gcpApiKey, sitchClientUrl } from './initialization-utils';
import { t } from './language-utils';
import { showError } from './misc-firestore-utils';
import { showNotice, showPrompt } from './notice-utils';
import { isPremiumActivated } from './user-utils';

export const isFeatureEnabled = (flag: eFeatureFlagTypes): boolean => {
  switch (flag) {
    case eFeatureFlagTypes.prod:
      return true;
    case eFeatureFlagTypes.test:
      return (
        [
          'NzJhBcZiEMMMDcjTQKB4stP6dqY2', // Nick
          'GlQPWNFYtge8TRXwYmwpP3txDTs1', // Cindy
          'XgaMpgMsQAOkqJP3RNpYOECcXGh1', // Cindy Test
          'aECyshhl28SC2jCUxc1iWlEQKZs1', // Cindy Test 2
          'thU6a3OaF8VvUUEmf67xhM7YPVX2',
          'vFgRmMdSmfbSqUsK4yxmYusIYy23',
        ].includes(store.state.userId) ||
        store.state.currUser.email.includes('@sitch.cards') ||
        store.state.currUser.email.includes('@sitch.app')
      );
    case eFeatureFlagTypes.off:
      return false;
  }
};

export const isiOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const localStoreSet = (fieldName: string, storageString: string) => {
  storage.setItem(fieldName, storageString);
};

export const localStoreGet = (fieldName: string) => {
  return storage.getItem(fieldName);
};

export const localStoreRemove = (fieldName: string) => {
  storage.removeItem(fieldName);
};

export const loadFonts = (callback: () => void) => {
  if (!store.state.fonts.length) {
    fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${gcpApiKey}`)
      .then((response) => {
        response
          .json()
          .then((obj) => {
            if (!obj.items) {
              showError(`Could not retrieve the font list. The items field is undefined. Probably a permissions error.`);
              return;
            }

            const allFonts = obj.items.map((f: any) => {
              const formattedVariants = f.variants.concat('bold');
              return {
                name: f.family,
                variants: formattedVariants,
              };
            });

            store.commit('fonts', allFonts);
            if (callback) {
              callback();
            }
          })
          .catch((error: any) => {
            showError(`Could not retrieve the font list. ${error?.message}`);
          });
      })
      .catch((error: any) => {
        showError(`Could not retrieve the font list. ${error?.message}`);
      });
  }
};

export const hardReload = () => {
  window.location.reload();
};

export const isSitchProdAccount = () => {
  return store.state.currUser.email === 'prod@sitch.app';
};

export const getCustomFieldValue = (customFieldData: CustomFieldData, forCsv = false): string => {
  if (customFieldData.value === undefined) {
    return t.notProvided;
  }
  switch (customFieldData.type) {
    case eCustomFieldTypes.date:
      return format(new Date(customFieldData.value?.toString()), 'MMM dd yyyy');
    case eCustomFieldTypes.checkbox:
      return customFieldData.value ? t.yes : t.no;
    case eCustomFieldTypes.outOf5:
      return `${customFieldData.value}/5`;
    case eCustomFieldTypes.fileUpload:
      if ((customFieldData.value as StorageFile[]).length) {
        return (customFieldData.value as StorageFile[])
          .map((storageFile) => {
            return forCsv ? `${storageFile.fileName} - ${storageFile.downloadUrl}` : `<a href="${storageFile.downloadUrl}" target="_blank">${storageFile.fileName}</a>`;
          })
          .join(forCsv ? ', ' : ',&nbsp');
      }
      return t.notProvided;
    case eCustomFieldTypes.signature: {
      const signatureValue = customFieldData.value as SignatureValue;
      return `<div class="signature-value"><small>${signatureValue.statement}</small><img class="signature-image" src="${signatureValue.image}" /></div>`;
    }
    default:
      return customFieldData.value?.toString();
  }
};

export const getLocationAddress = (addressData: any, place: any, modeFormOrEvent: any): DetailedAddress => {
  if (addressData === 'clearAddress') {
    return getEmptyDetailedAddress();
  }
  return {
    formattedAddress: place.formatted_address || '',
    address: {
      administrativeAreaLevel1: addressData.administrative_area_level_1 || '',
      administrativeAreaLevel2: addressData.administrative_area_level_2 || '',
      administrativeAreaLevel3: addressData.administrative_area_level_3 || '',
      administrativeAreaLevel4: addressData.administrative_area_level_4 || '',
      administrativeAreaLevel5: addressData.administrative_area_level_5 || '',
      streetAddress: addressData.street_address || '',
      intersection: addressData.intersection || '',
      political: addressData.political || '',
      country: addressData.country || '',
      colloquialArea: addressData.colloquial_area || '',
      locality: addressData.locality || '',
      sublocality: addressData.sublocality || '',
      sublocalityLevel1: addressData.sublocality_level_1 || '',
      sublocalityLevel2: addressData.sublocality_level_2 || '',
      sublocalityLevel3: addressData.sublocality_level_3 || '',
      sublocalityLevel4: addressData.sublocality_level_4 || '',
      sublocalityLevel5: addressData.sublocality_level_5 || '',
      neighborhood: addressData.neighborhood || '',
      postalCode: addressData.postal_code || '',
      route: addressData.route || '',
      streetNumber: addressData.street_number || '',
      premise: addressData.premise || '',
      subpremise: addressData.subpremise || '',
      plusCode: addressData.plus_code || '',
      floor: addressData.floor || '',
    },
    latitude: addressData.latitude || null,
    longitude: addressData.longitude || null,
    googlePlaceId: place.place_id || '',
    utcOffset: place.utc_offset_minutes || '',
    addressLine2: modeFormOrEvent.locationAddress?.addressLine2 || '',
    additionalAddressDetails: modeFormOrEvent.locationAddress?.additionalAddressDetails || '',
  };
};

export const addImageFileExtentionToFileName = (filename: string, mimeType: string | undefined) => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/jpg':
      if (!filename.includes('.jpg') && !filename.includes('.jpeg')) {
        filename += '.jpg';
      }
      break;
    case 'image/png':
      if (!filename.includes('.png')) {
        filename += '.png';
      }
  }

  return filename;
};

export const getParameterByName = (n: string): string => {
  const url: string = window.location.href;
  const name = n.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// export const setParameter = (n: string, val: string) => {
//   if (getParameterByName(n)) {
//     // Prevent adding duplicated params.
//     return;
//   }
//   const url = new URL(window.location.href);
//   url.searchParams.append(n, val);
//   window.history.replaceState({ path: url.href }, '', url.href);
// };

export const isMobileCheck = (): boolean => {
  let check = false;
  const typedWindow: any = window;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || typedWindow.opera);
  return check;
};

export const eNumToTextValueArray = (enumObj: any): TextValue[] => {
  const ret = [];
  for (const prop in enumObj) {
    if (isNaN(Number(prop))) {
      ret.push({
        text: t[prop] || prop,
        value: enumObj[prop],
      });
    }
  }
  return ret;
};

export const removeItemFromArrayAndReturn = (item: { id: string }, array: any[]): any[] => {
  return array.filter((currItem) => currItem !== item.id);
};

export const addHttp = (url: string | undefined, forceHttps = false): string => {
  if (!url) {
    return '';
  }
  url = url.replace(/(^\w+:|^)\/\//, '');
  url = forceHttps ? 'https://' + url : 'http://' + url;
  return url;
};

export const isAtMaxModes = (silent = false): boolean => {
  const numberOfModes = Object.keys(store.state.modes).length;
  const isDemoAccount = ['demo@sitch.cards', 'test@sitch.app'].includes(store.state.currUser.email);
  if (isDemoAccount) {
    return false;
  } else if (isPremiumActivated()) {
    if (numberOfModes < PREMIUM_MAX_SITCHES) {
      return false;
    } else if (!silent) {
      showNotice(t.cannotCreateNewModePremium.supplant([PREMIUM_MAX_SITCHES]));
    }
  } else if (store.state.currUserModeGateway.isSitchLinkActivated) {
    if (numberOfModes < PURCHASER_MAX_SITCHES) {
      return false;
    } else if (!silent) {
      showNotice(t.cannotCreateNewModeFree.supplant([PURCHASER_MAX_SITCHES, PREMIUM_MAX_SITCHES]));
    }
  } else {
    if (numberOfModes < STANDARD_MAX_SITCHES) {
      return false;
    } else if (!silent) {
      showNotice(t.cannotCreateNewModeFree.supplant([STANDARD_MAX_SITCHES, PREMIUM_MAX_SITCHES]));
    }
  }
  return true;
};

export const getModeIcon = (type: eModeType): string => {
  switch (type) {
    case eModeType.profile:
      return 'address-card';
    case eModeType.urlRedirect:
      return 'link';
    case eModeType.article:
      return 'newspaper';
    case eModeType.files:
      return 'archive';
    case eModeType.personalPayment:
      return 'money-bill-wave';
    case eModeType.businessPayment:
      return 'handshake';
    case eModeType.wifi:
      return 'wifi';
    case eModeType.site:
      return 'browser';
    case eModeType.linkList:
      return 'list';
    case eModeType.gallery:
      return 'images';
    case eModeType.group:
      return 'layer-group';
    case eModeType.shop:
      return 'store';
    case eModeType.html:
      return 'code';
    case eModeType.events:
      return 'calendar-alt';
    case eModeType.booking:
      return 'calendar-plus';
    case eModeType.customForm:
      return 'clipboard-list';
    case eModeType.blog:
      return 'megaphone';
    case eModeType.accordion:
      return 'window-maximize';
    case eModeType.returnTo:
      return 'tags';
    case eModeType.chat:
      return 'comments';
    case eModeType.chess:
      return 'chess';
    case eModeType.wordle:
      return 'border-all';
    case eModeType.trivia:
      return 'question-square';
    case eModeType.card:
      return 'book-open';
    default:
      return 'question';
  }
};

export const getOrderLink = (userPaymentRecord: UserPaymentRecord) => {
  return `${sitchClientUrl}/TransactionSummary?id=${userPaymentRecord.paymentIntentId}`;
};

export const getOrderItemTaxValues = (item: OrderItem | ShopItem, defaultTaxRate: number, totalAmount: number) => {
  const defaultTaxRateAsALessThanOneFloat = defaultTaxRate / 100;
  const uniqueTaxRateAsALessThanOneFloat = (item.uniqueTaxRate || 0) / 100;
  const taxRateToUse = item.hasUniqueTaxRate ? uniqueTaxRateAsALessThanOneFloat : defaultTaxRateAsALessThanOneFloat;
  const totalTaxAmount = Math.round(totalAmount * taxRateToUse);
  const totalAmountWithTax = totalAmount + totalTaxAmount;
  return {
    totalTaxAmount,
    totalAmountWithTax,
  };
};

export const isEmptyObject = (obj: any): boolean => {
  return obj && Object.entries(obj).length === 0 && obj.constructor === Object;
};
export const removeUndefinedValues = (obj: any) => {
  const newObj: any = {};
  for (const [key, val] of Object.entries(obj)) {
    if (val !== undefined) {
      newObj[key] = val;
    }
  }
  return newObj;
};

export const enforceMinMax = (vueComponent: any, dataVariableString: string, min: number, max: number, currency = '') => {
  let val = Number(vueComponent.$data[dataVariableString]);
  if (isNaN(val)) {
    val = 0;
  }
  if (val < min) {
    vueComponent.$data[dataVariableString] = min;
    if (currency) {
      showError(t.maxValue.supplant([formatCurrency(max, currency)]));
    } else {
      showError(t.minValue.supplant([min]));
    }
  } else if (val > max) {
    vueComponent.$data[dataVariableString] = max;
    if (currency) {
      showError(t.maxValue.supplant([formatCurrency(max, currency)]));
    } else {
      showError(t.maxValue.supplant([max]));
    }
  }
};

export const formatCurrency = (amount = 0, currency = '', showSign = false, addHtml = false): string => {
  let CENTS_DOLLARS_MULTIPLIER = 100;

  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    CENTS_DOLLARS_MULTIPLIER = 1;
  }

  // Convery from int to float now that the accounting is done.
  amount = amount / CENTS_DOLLARS_MULTIPLIER;

  const arr: string[] = Number(Math.abs(amount)).toFixed(2).split('.');
  let ret = '';
  if (showSign) {
    ret += addHtml ? `<span class="amount-sign">${amount > 0 ? '+' : '-'}</span>` : amount > 0 ? '+' : '-';
  }
  ret += addHtml ? `<span class="money-symbol">${currency.toUpperCase()}</span>` : currency.toUpperCase();
  const dollars: string = arr[0];
  const cents: string = arr[1];
  if (CENTS_DOLLARS_MULTIPLIER === 1) {
    ret += addHtml ? `<span>${dollars}</span>` : `${dollars}`;
  } else {
    ret += addHtml ? `<span>${dollars}</span><span class="small-amount">.${cents}</span>` : `${dollars}.${cents}`;
  }

  return ret;
};

export const routerDupeErrorCatch = (error: any) => {
  if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
    console.error(error);
  }
};

export const fixDamagedTimestamp = (fixDamagedTimestamp: DamagedTimestamp) => {
  if (fixDamagedTimestamp._nanoseconds && fixDamagedTimestamp._seconds) {
    const nanosecondsToMs = fixDamagedTimestamp._nanoseconds / 1_000_000;
    const secondsToMs = fixDamagedTimestamp._seconds * 1_000;
    return Timestamp.fromMillis(nanosecondsToMs + secondsToMs);
  }
  return Timestamp.fromMillis(Date.now());
};

export const firebaseUserFunctionError = (error: any) => {
  switch (error.code) {
    case 'auth/weak-password':
      showPrompt('This password is too weak, it must be at least 6 characters.');
      break;
    case 'auth/requires-recent-login':
      showPrompt('Recent sign in required. Sign out and sign back in to do this.');
      break;
    default:
      showError(`Something went wrong:`, error, true);
      break;
  }
};
